<script>
import { residentService } from "../../../helpers/backend/resident.service";
import { eventService } from "../../../helpers/backend/event.service";
import { eventLogService } from "../../../helpers/backend/event_log.service";
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  components: {
    StreamBarcodeReader
   },
  props: {
    isUpdateMode: Boolean,
  },
  data() {
    return {
      form: {
        event_id : null,
        resident_id : null,
      },
      selected_event : null,
      active_events : [{text:'Select event',value : null}],
      card_number : null,
      showModal: false,
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      residentModalShown : false,
      residentsData : null,
      selected_resident : null,
      fields: [
        { key: "ID", sortable: true, label: "ID" },
        { key: "nik", sortable: true, label: "NIK" },
        { key: "card_number", sortable: true, label: "Card Number" },
        { key: "name", sortable: true, label: "Name" },
        { key: "address", sortable: true, label: "Address" },
        { key: "phone_number", sortable: true, label: "Phone Number" },
      ],
      
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    },

  },
  methods: {
    onRowSelected(items) {
        this.selected_resident = items[0]
      },
    onModalChooseResidentHide(){
      // this.reset();
    },
    onModalChooseResidentOk(event){
      if (!this.selected_resident){
        event.preventDefault()
      }else{
        this.form.resident_id = this.selected_resident.ID
        this.form.event_id = this.selected_event.ID
        eventLogService.createOrUpdate(this.form)
        .then (
          (data) => {
             if (data) {
              this.reset();
              this.showDismissibleAlert = true;
              this.error = "";
              }
          },
          (error) => {
              this.showDismissibleAlert = true;
              this.error = "Fail to create event log, " + error;
              this.busy = false;
              event.preventDefault();
          }
        )
      }
    },
    onSubmit(event) {
      if (event != undefined) {
        event.preventDefault();
      }
      this.busy = true
      residentService.getByCardNumber(this.card_number)
            .then(
              (data) => {
                if (data != null && data.residents.length > 0) {
                  this.residentsData = data.residents
                  this.$bvModal.show("choose-resident-modal")
                  
                }else {
                  this.showDismissibleAlert = true;
                  this.error = "No resident with inputed card number";
                }
                this.busy = false;
                this.card_number = null;
              },
              (error) => {
                this.showDismissibleAlert = true;
                this.error = "Failed to Get Resident. Error : " + error;
                this.busy = false;
                this.card_number = null;
              }
            );
      // this.$bvModal
      //   .msgBoxConfirm("Are you sure to submit this Transaction?")
      //   .then((value) => {
      //     if (!value) return;
      //     this.busy = true;
      //     
      //   })
      //   .catch(() => {
      //     // An error occurred
      //   });
      //this.form.media = this.$refs.file.files[0]
    },
    reset() {
      // Reset our form values
      this.card_number = null;
      this.selected_resident = null;
      this.residentsData = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
      this.$emit("onReset");
    },
    onReset(event) {
      event.preventDefault();
      this.reset();
    },
    
    onLoaded(){

    },
    onDecode (result) {
      if (!isNaN(result) && !this.residentModalShown){
        this.card_number = result
        this.onSubmit()
      }
    },
    fetchData() {
      eventService.getActive().then(
        (data) => {
          this.active_events = this.active_events.concat(
            data.events.map(function (event) {
              return { text: event.event_name, value: event };
            })
          );
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get active events. Error : " + err;
        }
      );
    },
  },
};
</script>

<style scope>
.vue-suggestion,
.vs__input-group {
  display: inline-block;
  margin-right: 10px;
}
.vue-suggestion .vs__list {
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 400px;
  overflow-y: auto;
  border-bottom: 1px solid #023d7b;
  position: relative;
  z-index: 100;
}
.vue-suggestion .vs__list .vs__list-item {
  background-color: #fff;
  padding: 10px;
  border-left: 1px solid #023d7b;
  border-right: 1px solid #023d7b;
}
.vue-suggestion .vs__list .vs__list-item:last-child {
  border-bottom: none;
}
.vue-suggestion .vs__list .vs__list-item:hover {
  background-color: #eee !important;
}
.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
  position: absolute;
  z-index: 100;
}
.vue-suggestion .vs__list .vs__list-item {
  cursor: pointer;
}
.vue-suggestion .vs__list .vs__list-item.vs__item-active {
  background-color: #f3f6fa;
}
#products-select-table .table-responsive {
  height: 60vh;
}
</style>

<template>
  <div>
    <b-modal size="xl" title="Choose Resident" id="choose-resident-modal"
     :visible="residentModalShown"
     @ok="onModalChooseResidentOk"
     @hidden="onModalChooseResidentHide">
      <div class="table-responsive">
          <b-table
            :items="residentsData"
            :fields="fields"
            responsive="sm"
            select-mode="single"
            selectable
            @row-selected="onRowSelected"
          >
          </b-table>
      </div>
    </b-modal>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <b-overlay :show="busy" rounded="lg" opacity="0.6">
      <div class="card">
        <div class="card-body overflow-auto" style="max-height: 600px">
          <div>
            <div class="row">
              <div class="col-xl-3">
                <b-form-group
                  id="input-group-event-id"
                  label="Event : "
                  label-for="input-event-id"
                >
                  <b-form-select
                  id="input-event-id"
                  v-model="selected_event"
                  :options="active_events"
                ></b-form-select>
                </b-form-group>
              </div>
              
            </div>
            <div class="row" v-if="selected_event">
              <div class="col-xl-3">
                <b-form-group label="Event Name:">
                  <div class="card-title">
                    {{ selected_event.event_name }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-xl-12">
                <b-form-group label="Event Description:">
                  <div class="card-title">
                    {{ selected_event.description }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-xl-6">
                <b-form-group label="Event Start date:">
                  <div class="card-title">
                    {{ selected_event.start_date }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-xl-6">
                <b-form-group label="Event End date:">
                  <div class="card-title">
                    {{ selected_event.finish_date }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-xl-3">
                <b-form-group label="Event Total Claim:">
                  <div class="card-title">
                    {{ selected_event.total_claimed }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-xl-3">
                <b-form-group label="Event Max Total Claim:">
                  <div class="card-title">
                    {{ selected_event.total_max_claim }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-xl-3">
                <b-form-group label="Event Max Claim per person:">
                  <div class="card-title">
                    {{ selected_event.max_claim }}
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row" v-if="selected_event">
              <div class="col-xl-6">
                <b-form-group
                  id="input-group-card-number"
                  label="Card Number:"
                  label-for="input-card-number"
                >
                  <b-form-input
                  id="input-card-number"
                  v-model="card_number"
                  type="number"
                  placeholder="Enter card number"
                  required
                  ref="card_number"
                  @keydown.enter.native="onSubmit"
                ></b-form-input>
                </b-form-group>
                
              </div>
              <div class="col-xl-6">
                <b-button
                  type="submit"
                  @click="onSubmit"
                  class="float-right"
                  variant="primary"
                  >Submit</b-button
                >
              </div>
              
            </div>
            <hr />
            <div class="row" v-if="selected_event">
              <div class="col-xl-12">
                <StreamBarcodeReader
                  @decode="onDecode"
                  @loaded="onLoaded"
              ></StreamBarcodeReader>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>



